/*
// Use this to Redirect to home page
import { useEffect } from "react"
import { navigate } from "gatsby"

const NotFoundPage = () => {
	useEffect(() => {
		navigate("/")
	}, [])
	return null
}
*/

import React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout pageTitle="Not Found">
    <p>Page not found</p>
  </Layout>
)

export default NotFoundPage
